import { Link, useLocation } from "@remix-run/react";
import { clsx } from "clsx";
import { motion, MotionConfig, useReducedMotion } from "framer-motion";
import * as React from "react";

import { Button } from "~/components/button";
import { Container } from "~/components/container";
import { Footer } from "~/components/footer";
import { GridPattern } from "~/components/grid-pattern";
import { Offices } from "~/components/offices";
import { SocialMedia } from "~/components/social-media";

import { SpriteName, Svg } from "./svg";

function Header({
  panelId,
  icon,
  expanded,
  onToggle,
  toggleRef,
  invert = false,
}: {
  panelId: string;
  icon: SpriteName;
  expanded: boolean;
  onToggle: () => void;
  toggleRef: React.RefObject<HTMLButtonElement>;
  invert?: boolean;
}) {
  return (
    <Container>
      <div className="flex items-center justify-between">
        <Link
          to="/"
          aria-label="Home"
          className={clsx(
            expanded || invert ? "text-white" : "text-black",
            "transition-all duration-300",
          )}
        >
          <Svg name="logo-full" className="h-8 w-36" />
        </Link>
        <div className="flex items-center gap-x-8">
          <Button to="/contact" invert={invert}>
            Contact us
          </Button>
          <button
            ref={toggleRef}
            type="button"
            onClick={onToggle}
            aria-expanded={expanded ? "true" : "false"}
            aria-controls={panelId}
            className={clsx(
              "group -m-2.5 rounded-full p-2.5 transition",
              invert ? "hover:bg-white/10" : "hover:bg-neutral-950/10",
            )}
            aria-label="Toggle navigation"
          >
            <Svg
              name={icon}
              className={clsx(
                "h-6 w-6",
                invert
                  ? "fill-white group-hover:fill-neutral-200"
                  : "fill-neutral-950 group-hover:fill-neutral-700",
              )}
            />
          </button>
        </div>
      </div>
    </Container>
  );
}

function NavigationRow({ children }: { children: React.ReactNode }) {
  return (
    <div className="even:mt-px sm:bg-neutral-950">
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2">{children}</div>
      </Container>
    </div>
  );
}

function NavigationItem({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) {
  return (
    <Link
      to={to}
      className="group relative isolate -mx-6 bg-neutral-950 px-6 py-10 even:mt-px sm:mx-0 sm:px-0 sm:py-16 sm:odd:pr-16 sm:even:mt-0 sm:even:border-l sm:even:border-neutral-800 sm:even:pl-16"
    >
      {children}
      <span className="absolute inset-y-0 -z-10 w-screen bg-neutral-900 opacity-0 transition group-odd:right-0 group-even:left-0 group-hover:opacity-100" />
    </Link>
  );
}

function Navigation() {
  return (
    <nav className="mt-px font-display text-2xl font-medium tracking-tight text-white">
      <NavigationRow>
        <NavigationItem to="/about">About Us</NavigationItem>
      </NavigationRow>
    </nav>
  );
}

function RootLayoutInner({ children }: { children: React.ReactNode }) {
  let panelId = React.useId();
  let [expanded, setExpanded] = React.useState(false);
  let openRef = React.useRef<React.ElementRef<"button">>(null);
  let closeRef = React.useRef<React.ElementRef<"button">>(null);
  let navRef = React.useRef<React.ElementRef<"div">>(null);
  let shouldReduceMotion = useReducedMotion();

  React.useEffect(() => {
    function onClick(event: MouseEvent) {
      if (
        event.target instanceof HTMLElement &&
        event.target.closest("a")?.href === window.location.href
      ) {
        setExpanded(false);
      }
    }

    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <MotionConfig transition={shouldReduceMotion ? { duration: 0 } : undefined}>
      <header>
        <div
          className="absolute left-0 right-0 top-2 z-40 pt-14"
          aria-hidden={expanded ? "true" : undefined}
          // @ts-expect-error (https://github.com/facebook/react/issues/17157)
          inert={expanded ? "" : undefined}
        >
          <Header
            panelId={panelId}
            icon="menu"
            toggleRef={openRef}
            expanded={expanded}
            onToggle={() => {
              setExpanded((expanded) => !expanded);
              window.setTimeout(() => {
                closeRef.current?.focus({ preventScroll: true });
              });
            }}
          />
        </div>

        <motion.div
          layout
          id={panelId}
          style={{ height: expanded ? "auto" : "0.5rem" }}
          className="relative z-50 overflow-hidden bg-neutral-950 pt-2"
          aria-hidden={expanded ? undefined : "true"}
          // @ts-expect-error (https://github.com/facebook/react/issues/17157)
          inert={expanded ? undefined : ""}
        >
          <motion.div layout className="bg-neutral-800">
            <div ref={navRef} className="bg-neutral-950 pb-16 pt-14">
              <Header
                invert
                panelId={panelId}
                icon="x"
                toggleRef={closeRef}
                expanded={expanded}
                onToggle={() => {
                  setExpanded((expanded) => !expanded);
                  window.setTimeout(() =>
                    openRef.current?.focus({ preventScroll: true }),
                  );
                }}
              />
            </div>

            <Navigation />

            <div className="relative bg-neutral-950 before:absolute before:inset-x-0 before:top-0 before:h-px before:bg-neutral-800">
              <Container>
                <div className="grid grid-cols-1 gap-y-10 pb-16 pt-10 sm:grid-cols-2 sm:pt-16">
                  <div>
                    <h2 className="font-display text-base font-semibold text-white">
                      Our office
                    </h2>
                    <Offices
                      invert
                      className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2"
                    />
                  </div>
                  <div className="sm:border-l sm:border-transparent sm:pl-16">
                    <h2 className="font-display text-base font-semibold text-white">
                      Follow us
                    </h2>
                    <SocialMedia className="mt-6" invert />
                  </div>
                </div>
              </Container>
            </div>
          </motion.div>
        </motion.div>
      </header>

      <motion.div
        layout
        style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
        className="relative flex flex-auto overflow-hidden bg-white pt-14"
      >
        <motion.div
          layout
          className="relative isolate flex w-full flex-col pt-9"
        >
          <GridPattern
            className="absolute inset-x-0 -top-14 -z-10 h-[1000px] w-full fill-accent-50 stroke-accent-950/5 [mask-image:linear-gradient(to_bottom_left,white_40%,transparent_50%)]"
            yOffset={-96}
            interactive
          />

          <main className="w-full flex-auto">{children}</main>

          <Footer />
        </motion.div>
      </motion.div>
    </MotionConfig>
  );
}

export function RootLayout({ children }: { children: React.ReactNode }) {
  let { pathname } = useLocation();
  return <RootLayoutInner key={pathname}>{children}</RootLayoutInner>;
}
