import { json } from "@remix-run/node";
import type { HeadersFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";

import { Container } from "./components/container";
import { FadeIn } from "./components/fade-in";
import { RootLayout } from "./components/root-layout";
import "./styles/tailwind.css";

export function loader({ request }: LoaderFunctionArgs) {
  let url = new URL(request.url);
  let isProductionDeployment = [
    "www.bmcreations.dev",
    "bmcreations.dev",
  ].includes(url.hostname);
  let headers = new Headers();
  if (!isProductionDeployment) {
    headers.set("x-robots-tag", "noindex");
  }
  return json({}, { headers });
}

export let headers: HeadersFunction = ({ loaderHeaders }) => {
  let responseHeaders = new Headers();
  let robots = loaderHeaders.get("x-robots-tag");
  if (robots) {
    responseHeaders.set("x-robots-tag", robots);
  }
  return responseHeaders;
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className="h-full bg-neutral-950 text-base antialiased">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex min-h-full flex-col">
        <RootLayout>{children}</RootLayout>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(Outlet);

export function ErrorBoundary() {
  let error = useRouteError();
  if (process.env.NODE_ENV === "production") {
    captureRemixErrorBoundaryError(error);
  }

  return (
    <Container className="flex h-full items-center pt-24 sm:pt-32 lg:pt-40">
      <FadeIn className="flex max-w-xl flex-col items-center text-center">
        {isRouteErrorResponse(error) ? (
          <>
            <p className="font-display text-4xl font-semibold text-neutral-950 sm:text-5xl">
              {error.status}
            </p>
            <h1 className="mt-4 font-display text-2xl font-semibold text-neutral-950">
              {error.statusText}
            </h1>
            {error.status === 404 ? (
              <p className="mt-2 text-sm text-neutral-600">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
            ) : null}
            <Link
              to="/"
              className="mt-4 text-sm font-semibold text-neutral-950 transition hover:text-neutral-700"
            >
              Go to the home page
            </Link>
          </>
        ) : error instanceof Error ? (
          <>
            <p className="font-display text-4xl font-semibold text-neutral-950 sm:text-5xl">
              500
            </p>
            <h1 className="mt-4 font-display text-2xl font-semibold text-neutral-950">
              Something went wrong
            </h1>

            <pre>
              <code>{error.message}</code>
            </pre>

            <Link
              to=""
              replace
              className="mt-4 text-sm font-semibold text-neutral-950 transition hover:text-neutral-700"
            >
              Try again
            </Link>
          </>
        ) : (
          <>
            <p className="font-display text-4xl font-semibold text-neutral-950 sm:text-5xl">
              500
            </p>
            <h1 className="mt-4 font-display text-2xl font-semibold text-neutral-950">
              Something went wrong
            </h1>
            <p className="mt-2 text-sm text-neutral-600">
              Sorry, we couldn’t load the page you’re looking for.
            </p>
          </>
        )}
      </FadeIn>
    </Container>
  );
}
