import { Link, useFetcher } from "@remix-run/react";
import { clsx } from "clsx";
import * as React from "react";

import { Container } from "~/components/container";
import { FadeIn } from "~/components/fade-in";
import { socialMediaProfiles } from "~/components/social-media";
import { action as subscribeToNewsletterAction } from "~/routes/[_]actions.newsletter";

import { Svg } from "./svg";

const navigation = [
  {
    title: "Company",
    links: [
      { title: "About", href: "/about" },
      { title: "Contact us", href: "/contact" },
    ],
  },
  {
    title: "Connect",
    links: socialMediaProfiles,
  },
];

function Navigation() {
  return (
    <nav>
      <ul className="grid grid-cols-2 gap-8 sm:grid-cols-3">
        {navigation.map((section, sectionIndex) => (
          <li key={sectionIndex}>
            <div className="font-display text-sm font-semibold tracking-wider text-neutral-950">
              {section.title}
            </div>
            <ul className="mt-4 text-sm text-neutral-700">
              {section.links.map((link, linkIndex) => (
                <li key={linkIndex} className="mt-4">
                  <Link
                    to={link.href}
                    className="transition hover:text-neutral-950"
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
}

function ArrowIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg viewBox="0 0 16 6" aria-hidden="true" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3 10 .5v2H0v1h10v2L16 3Z"
      />
    </svg>
  );
}

function NewsletterForm() {
  let fetcher = useFetcher<typeof subscribeToNewsletterAction>();
  let inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data?.ok && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [fetcher.state, fetcher.data]);

  return (
    <div className="max-w-sm">
      <fetcher.Form
        method="post"
        action="/_actions/newsletter"
        className={clsx({
          "opacity-50": fetcher.state === "submitting",
        })}
      >
        <h2 className="font-display text-sm font-semibold tracking-wider text-neutral-950">
          Sign up for our newsletter
        </h2>
        <p className="mt-4 text-sm text-neutral-700">
          Subscribe to get the latest design news, articles, resources and
          inspiration.
        </p>
        <div className="relative mt-6">
          <input
            type="email"
            placeholder="Email address"
            name="email"
            autoComplete="email"
            aria-label="Email address"
            className="block w-full rounded-2xl border border-neutral-300 bg-transparent py-4 pl-6 pr-20 text-base/6 text-neutral-950 ring-4 ring-transparent transition placeholder:text-neutral-500 focus:border-neutral-950 focus:outline-none focus:ring-neutral-950/5"
            ref={inputRef}
            aria-invalid={fetcher.data?.errors?.email ? "true" : undefined}
          />
          <div className="absolute inset-y-1 right-1 flex justify-end">
            <button
              type="submit"
              aria-label="Submit"
              className="flex aspect-square h-full items-center justify-center rounded-xl bg-neutral-950 text-white transition hover:bg-neutral-800"
              onClick={(event) => {
                if (fetcher.state === "submitting") {
                  event.preventDefault();
                }
              }}
            >
              <ArrowIcon className="w-4" />
            </button>
          </div>
        </div>
      </fetcher.Form>

      <div aria-live="polite" className="pt-4">
        {fetcher.state === "idle" && fetcher.data?.ok ? (
          <div>
            <b className="font-semibold text-green-500">Got it!</b> Please go{" "}
            <b className="font-semibold text-red-500">check your email</b> to
            confirm your subscription, otherwise you won&apos;t get our email.
          </div>
        ) : null}
        {fetcher.state === "idle" && fetcher.data?.errors?.email ? (
          <div className="text-red-600">{fetcher.data.errors.email}</div>
        ) : null}
      </div>
    </div>
  );
}

export function Footer() {
  return (
    <Container as="footer" className="mt-24 w-full sm:mt-32 lg:mt-40">
      <FadeIn>
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
          <Navigation />
          <div className="flex lg:justify-end">
            <NewsletterForm />
          </div>
        </div>
        <div className="mb-20 mt-24 flex flex-wrap items-end justify-between gap-x-6 gap-y-4 border-t border-neutral-950/10 pt-12">
          <Link to="/" aria-label="Home">
            <Svg name="logo-full" className="h-8 w-36" />
          </Link>
          <p className="text-sm text-neutral-700">
            © bmcreations. {new Date().getFullYear()}
          </p>
        </div>
      </FadeIn>
    </Container>
  );
}
